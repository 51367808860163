import { CloseIcon } from 'assets/icons/CloseIcon';
import { IconButton } from 'components/IconButton/IconButton';
import { ReactNode } from 'react';

import {
  DialogStyleProps,
  StyledDialog,
  StyledDialogContent,
  StyledDialogHeader,
  TitleContainer,
  TitleIconContainer,
} from './Dialog.style';

type Props = {
  isOpen: boolean;
  titleIcon?: ReactNode;
  onClose?: () => void;
  title?: string;
  titleIconColor?: 'grey' | 'orange' | 'blue';
  showCloseIcon?: boolean;
  closeIconSizeInPx?: number;
  children: ReactNode;
  titleColor?: string;
  noMarginBottomOnHeader?: boolean;
  fullPageDialog?: boolean;
} & DialogStyleProps;

export const Dialog = ({
  isOpen,
  titleIcon,
  onClose,
  title = '',
  titleIconColor,
  showCloseIcon = false,
  closeIconSizeInPx = 24,
  widthInPercent,
  heightInPercent,
  children,
  titleColor,
  noMarginBottomOnHeader = false,
  fullPageDialog = false,
  minHeightPx,
}: Props): JSX.Element => {
  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      scroll="paper"
      $widthInPercent={widthInPercent}
      $heightInPercent={heightInPercent}
      $borderTopColor={titleColor}
      $fullPageDialog={fullPageDialog}
      $minHeightPx={minHeightPx}
    >
      <StyledDialogHeader $noMarginBottom={noMarginBottomOnHeader}>
        {titleIcon && (
          <TitleIconContainer titleIconColor={titleIconColor}>{titleIcon}</TitleIconContainer>
        )}
        <TitleContainer color={titleColor} $showCloseIcon={showCloseIcon}>
          {title}
        </TitleContainer>
        {onClose && showCloseIcon && (
          <IconButton size="medium" displayStyle="grey" onClick={onClose}>
            <CloseIcon widthInPx={closeIconSizeInPx} />
          </IconButton>
        )}
      </StyledDialogHeader>
      <StyledDialogContent>{children}</StyledDialogContent>
    </StyledDialog>
  );
};
